.container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.item {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-bottom: 3px;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: var(--gray-light-text);
  border-bottom: 1.5px solid var(--gray-light);
  transition: var(--transition);
  cursor: pointer;

  &:not(.current):hover {
    color: var(--primary);
  }

  &.current {
    color: var(--primary);
    border-color: var(--primary);
  }

  &.big {
    font-size: 1rem;
    line-height: 0.9375rem;
    padding-bottom: 15px;
    border-bottom-width: 2px;
  }
}
