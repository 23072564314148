.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid var(--gray-text);
  border-right: 1px solid var(--gray-text);
  transition: var(--transition);

  &:before,
  &:after {
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
    background-color: var(--gray-dark);
    opacity: 0;
    transition: var(--transition);
  }

  &:before {
    width: calc(100% + 2px);
    height: 1px;
  }

  &:after {
    width: 1px;
    height: calc(100% + 2px);
  }

  &:hover {
    border-color: var(--gray-dark);

    &:before,
    &:after {
      opacity: 1;
    }
  }

  &.grid {
    min-width: 270px;
    flex: 1;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 8px;
}

.year {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tabs {
  margin-bottom: 20px;
}

.tabContent {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 12px;
  margin-bottom: auto;
  min-height: calc(5.3125rem + 20px);

  & + .buttons,
  & + .details {
    margin-top: 15px;
  }
}

.priceTabContent {
  padding: 16px;
  border-radius: 6px;
  background: var(--gray-light-menu);
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &Title,
  &Value {
    font-size: 0.875rem;
  }

  &Title {
    font-weight: 500;
    line-height: 1.0625rem;
    margin-right: auto;
    white-space: nowrap;
    text-transform: capitalize;
    color: var(--gray-description);
  }

  &Value {
    line-height: 1.0625rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    color: #000000;
    -webkit-box-orient: vertical;
  }
}

.priceInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &Title,
  &Value {
    font-size: 1rem;
    line-height: 1.0625rem;
    @media screen and (min-width: 1920px) {
      font-size: 1.125rem;
    }
  }

  &Title {
    font-weight: 500;
    line-height: 1.0625rem;
    margin-right: auto;
    white-space: nowrap;
    text-transform: capitalize;
    color: var(--gray-description);
    // padding-right: 16px;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  &Value {
    line-height: 1.0625rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    color: #000000;
    -webkit-box-orient: vertical;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
}

.viewDetails {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  color: var(--primary);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--primary-dark);
  }
}

.buttons {
  margin-bottom: 15px;
}

.button {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.description {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;

  &Container {
    display: flex;
    flex-direction: column;
    min-width: calc(50% - 10px);
  }

  .name {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .make {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .name,
  .make {
    color: var(--black-dark);
  }

  .stock {
    margin-top: 20px;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--gray-description);

    b {
      font-weight: 500;
    }
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 700 !important;
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: var(--primary);

  &Small {
    font-weight: 500 !important;
    font-size: 1rem;
    line-height: 1rem;
    flex-shrink: 0;
    margin-left: 10px;
  }

  &.priceWithMt {
    margin-top: 4px;
  }
}
