.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-width);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 30px 15px 0;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 768px) {
    margin: 70px auto 90px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding: 0 105px;
  }
}

.title {
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }

  & + .tabs {
    margin-top: 25px;
  }
}

.text {
  width: 100%;
  margin: 0 auto 35px;
  text-align: center;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &Form {
    margin-top: 30px;
    white-space: pre;
  }
}

.tabs {
  margin-bottom: 25px;
}

.submitButton {
  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-top: 25px;
  }

  @media screen and (min-width: 768px) {
    margin-top: 50px;
  }

  @media screen and (min-width: 1280px) {
    margin-left: auto;
    width: calc(50% - 10px);
  }
}

.checkboxContainer {
  @media screen and (min-width: 768px) {
    margin-top: 20px;
    margin-left: auto;
    width: calc(50% - 10px);
  }
}

.errorMessage {
  margin-top: 10px;
  font-size: 0.75rem;
  line-height: 0.625rem;
  color: var(--error);
}
