.favorites {
  &Content {
    display: grid;
    min-height: 30vh;
    grid-template-columns: repeat(4, 1fr);

    &Row {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-device-width: 279px) and (max-device-width: 767px) {
      margin-bottom: 20px;
    }

    @media screen and (min-device-width: 768px) {
      flex: 1;
    }
  }

  &Header {
    width: 100%;
    border-bottom: 1px solid var(--gray-text);

    @media screen and (min-device-width: 279px) and (max-device-width: 767px) {
      padding: 15px;
    }

    @media screen and (min-device-width: 768px) {
      display: flex;
      align-items: center;
      padding: 40px 25px;
    }
    &Status {
      display: flex;

      @media screen and (min-width: 768px) {
        margin: 0 auto;
      }

      &Heading {
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 1.6875rem;

        @media screen and (min-width: 768px) {
          margin-right: 40px;
        }
      }

      &Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        cursor: pointer;

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    &Back {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        path {
          fill: var(--black);
        }
      }

      &Text {
        margin-left: 5px;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--black);
      }
    }
  }

  @media screen and (min-device-width: 279px) and (max-device-width: 544px) {
    &Content {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (min-device-width: 545px) and (max-device-width: 849px) {
    &Content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-device-width: 767px) {
    &Content {
      &Row {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  @media screen and (min-device-width: 850px) and (max-device-width: 1280px) {
    &Content {
      grid-template-columns: repeat(3, 1fr);

      &Row {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.clearAll {
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--primary);
  transition: var(--transition);

  &:hover {
    color: var(--primary-dark);
  }
}
